.hero-section .coinopolis {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-section .coinopolis {
  background-image: linear-gradient(
    150deg,
    var(--secondary-color) 0%,
    var(--primary-color) 78%
  );
}
.hero-section {
  position: relative;
  background-color: var(--footer-bg-color);
  z-index: 1;
}

.hero-section .coinopolis {
  position: relative;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
}

.hero-section .coinopolis::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 45px;
  background-image: linear-gradient(
    135deg,
    var(--primary-color) 10%,
    var(--secondary-color)
  );
  top: 50%;
  left: calc(100% + 20px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hero-section h1 {
  color: var(--white-color);
}

.hero-section p {
  font-size: 1rem;
}

.button-group {
  margin-top: 30px;
}

.button-group a {
  margin-right: 10px;
}

.button-group a:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .button-group a {
    margin-top: 0;
    margin-right: 5px;
  }
  .button-group a .btn {
    padding: 12px 20px;
  }
}

.shape {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  line-height: 0;
  direction: ltr;
  overflow: hidden;
  z-index: -1;
}

.shape.shape-bottom svg {
  display: block;
  height: 275px;
  width: calc(228% + 1.3px);
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.bg-shape {
  position: absolute;
  height: 60%;
  width: 55%;
  top: 35%;
  right: -5%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

@media (max-width: 767px) {
  .bg-shape {
    height: 80%;
    width: 80%;
    top: 40%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media (max-width: 575px) {
  .bg-shape {
    height: 100%;
    width: 100%;
    top: 70%;
  }
}

.shape-bottom {
  position: absolute;
  top: auto;
  bottom: -1px;
  left: 0;
  right: 0;
  z-index: -1;
}

/* NFT Card */

.header-card .card {
  width: 22rem;
  max-width: 90%;
  border: solid 4px #fff;
  border-radius: 2rem;
  padding: 0.75rem;
  text-decoration: none;
  color: inherit;
  background: #eceff1;
  transition: all 400ms ease;
}
.header-card .card:hover {
  background: #fff;
  box-shadow: 0 16px 32px #cfd8dc;
}
.header-card .card__body {
  background: #fff;
  border-radius: 1.25rem;
  padding: 0.75rem;
}
.header-card .card__top {
  display: flex;
  font-weight: 500;
}
.header-card .card__owner,
.header-card .card__creator {
  width: 50%;
  display: flex;
}
.header-card .card__owner {
  position: relative;
}
/* .header-card .card__owner::after {
  content: "";
  position: absolute;
  top: 0;
  right: -1px;
  width: 2px;
  height: 100%;
  border-radius: 2px;
  background: #eceff1;
} */
.header-card .card__creator {
  flex-direction: row-reverse;
  text-align: right;
}
.header-card .card__avatar {
  width: 3rem;
  height: 3rem;
  background: #eceff1;
  border-radius: 100%;
  flex-shrink: 0;
}
.header-card .card__user {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header-card .card__user__title {
  font-size: 0.75rem;
  color: #b0bec5;
}
.header-card .card__image {
  padding-top: 118%;
  position: relative;
  overflow: hidden;
  margin-top: 0.75rem;
  border-radius: 1rem;
  background: #eceff1;
  box-shadow: 0 16px 32px #eceff1;
}
.header-card .card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header-card .card__info {
  margin: 0.75rem;
  display: flex;
  justify-content: space-between;
}
.header-card .card__info p {
  margin: 0;
}
.header-card .card__footer {
  background: #fff;
  padding: 0.75rem;
  border-radius: 0.75rem 0.75rem 1.75rem 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
