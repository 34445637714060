/* .... */
.navbar {
  /* background: linear-gradient(
    90deg,
    rgb(247, 121, 53) 0%,
    rgb(248, 68, 197) 100%
  ); */
  background-color: var(--footer-bg-color);
  height: 100px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  z-index: 2;
}

.navbar-logo {
  color: var(--primary-color);
  justify-self: start;
  /* margin-left: 20px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    150deg,
    var(--secondary-color) 0%,
    var(--primary-color) 78%
  );
}
.navbar-logo {
  color: var(--primary-color);
  justify-self: start;
  /* margin-left: 20px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  font-weight: bolder;
}
.navbar-logo:hover {
  color: var(--primary-color);
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  /* width: 70vw; */
  justify-content: end;
  /* margin-right: 2rem; */
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 600;
}

.nav-links:hover {
  color: var(--primary-color);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/* .... */
.btn {
  position: relative;
  font-size: 1rem;
  font-weight: 700;
  color: var(--white-color);
  line-height: 1;
  text-align: center;
  padding: 16px 34px;
  border: 0 none;
  border-radius: 100px;
  outline: 0 none;
  cursor: pointer;
  z-index: 1;
}
.btn:hover,
.btn:focus,
.btn:active {
  color: var(--white-color);
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.btn.btn-bordered-white {
  background: transparent none repeat scroll 0 0;
  border: 2px solid #fff;
  background-image: linear-gradient(
    135deg,
    var(--primary-color) 10%,
    var(--secondary-color)
  );
}

.btn.btn-bordered-white::after {
  position: absolute;
  content: "";
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background-image: linear-gradient(
    135deg,
    var(--secondary-color) 10% var(--primary-color)
  );
  top: -2px;
  left: -2px;
  border-radius: 100px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: -1;
}

.btn.btn-bordered-white:hover,
.btn.btn-bordered-white:focus {
  border-color: transparent;
  outline: 0 none;
}

.btn.btn-bordered-white:hover::after,
.btn.btn-bordered-white:focus::after {
  opacity: 1;
}
/* .... */

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 50px;
    justify-content: start;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}
