.submenu-submenu {
  /* width: 10rem; */
  position: absolute;
  top: calc(80% + 0.25rem);
  list-style: none;
  text-align: left;
  transition: opacity 150ms ease-in-out;
}

.submenu-submenu li {
  background: rgb(255, 255, 255);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  padding: 0;

  cursor: pointer;
}

.submenu-submenu li a:hover {
  background: rgb(255, 255, 255);
  color: #4528dc;
}

.submenu-submenu.clicked {
  display: none;
}

.submenu-item {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 10px 20px 10px 20px;
}

.submenu-submenu > .link:focus + .submenu-submenu {
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .submenu-submenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30vh;
    position: relative;
    /* top: 10px; */
    /* left: -100%; */
    opacity: 1;
    transition: all 0.5s ease;
  }

  .submenu-submenu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .submenu-submenu {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
}
