/* Styles go here */
:root {
  --primary-font: "Jost", sans-serif;
  --secondary-font: "Poppins", sans-serif;
  --h1-size: 4rem;
  --h1-weight: 900;
  --h2-size: 3rem;
  --h2-weight: 700;
  --h3-size: 2rem;
  --h3-weight: 600;
  --h4-size: 1.5rem;
  --h4-weight: 600;
  --h5-size: 1.2rem;
  --h5-weight: 600;
  --h6-size: 1rem;
  --h6-weight: 600;
  --p-size: 1rem;
  --p-weight: 400;
  --header-bg-color: #121117;
  --nav-brand-height: 55px;
  --top-nav-item-color: #f5f5f5;
  --nav-item-color: #121117;
  --primary-color: #4528dc;
  --secondary-color: #57048a;
  --white-color: #f9f9f9;
  --black-color: #040402;
  --grey-color: #f3f5f7;
  --dark-grey-color: #191919;
  --primary-t-color: #f5f5f5;
  --secondary-t-color: #f5f5f5;
  --primary-p-color: #8e8e8e;
  --secondary-p-color: #e1e1e1;
  --primary-b-color: #f5f5f5;
  --primary-l-color: rgba(0, 0, 0, 0.12);
  --secondary-l-color: rgba(255, 255, 255, 0.12);
  --valid-color: #007a4d;
  --invalid-color: #e34f4f;
  --primary-bg-color: #09080d;
  --primary-bg-color-2: #eeeeee;
  --primary-bg-color-3: #e5e5e5;
  --secondary-bg-color: #040402;
  --secondary-bg-color-2: #111111;
  --secondary-bg-color-3: #191919;
  --card-bg-color: #16151a;
  --footer-bg-color: #121117;
}
body {
  font-family: var(--secondary-font);
  color: var(--primary-p-color);
  background-color: var(--primary-bg-color) !important;
}
h1 {
  margin-bottom: 1.5rem;
  font-size: var(--h1-size);
  font-weight: var(--h1-weight);
  line-height: 1.2;
  font-family: var(--primary-font);
  color: var(--primary-color);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

h1 em {
  display: block;
  font-size: var(--h2-size);
  font-weight: 300;
}

h2 {
  margin: 1.5rem 0;
  font-size: var(--h2-size);
  font-weight: var(--h2-weight);
  line-height: 1.2;
  font-family: var(--primary-font);
  color: var(--primary-t-color);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.odd h2 {
  color: var(--white-color);
}

h3 {
  margin: 1.5rem 0;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: var(--primary-font);
  color: var(--primary-t-color);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

h4 {
  margin: 1.5rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: var(--primary-font);
  color: var(--primary-t-color);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.odd h4 {
  color: var(--secondary-t-color);
}

h5 {
  margin: 1.5rem 0;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: var(--primary-font);
  color: var(--primary-t-color);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

h6 {
  margin: 1.5rem 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: var(--primary-font);
  color: var(--primary-t-color);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
* {
  box-sizing: border-box;
  /* margin: 0;
  padding: 0; */
  font-family: "Poppins", sans-serif;
}
a {
  text-decoration: none !important;
}
h1 {
  margin-bottom: 1.5rem;
  font-size: var(--h1-size);
  font-weight: var(--h1-weight);
  line-height: 1.2;
  font-family: var(--primary-font);
  color: var(--primary-color);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
section {
  width: 100%;
  padding: 100px 0;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
/* ... */
.id {
  border-radius: 50%;
}

.network {
  width: 125px;
  height: 43px;
  color: #ffffff;
  background-color: #fd5da8;
  border: 0.1mm ridge #55ff55;
}

.balance {
  width: 125px;
  height: 43px;
  color: #ffffff;
  background-color: #fd5da8;
  border: 0.1mm ridge #55ff55;
}

.account {
  width: 150px;
  height: 43px;
  color: #55ff55;
  background-color: #fd5da8;
  border: 0.1mm ridge #55ff55;
}

/* .bgDark7 {
  background-color: #ffffff;
} */
/* 
.loading {
  height: 1440px;
  background-color: #1d1d1d;
} */

/* .main {
  height: 1440px;
  background-color: #ffffff;
} */

.main {
  height: 700px;
  /* background-color: #ffffff; */
}

/* p {
  margin: 5px;
} */

.social-icons {
  height: 50px;
  width: 50px;
  padding-left: 10px;
}
.social-icons:hover {
  height: 50px;
  width: 50px;
}

/* FooterNew Style  */

.footer-area {
  background-color: var(--footer-bg-color);
  color: var(--secondary-p-color);
}

.footer-area .footer-top {
  padding: 100px 0;
}

@media (max-width: 767px) {
  .footer-area .footer-top {
    padding: 80px 0;
  }
  body footer .contacts {
    left: 0;
    margin-bottom: 30px;
  }
  body footer .contacts:before {
    left: 0;
    width: 100%;
  }
  body footer .contacts:after {
    left: 0;
    width: 100%;
  }
  body .footer-area .footer-top {
    padding: 80px 0;
  }
  body .footer-area .items .item {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .navbar-logo2 {
    position: absolute;
    top: -90px;
    left: -31px;
    transform: translate(25%, 50%);
  }
  :root h1 {
    font-size: 3rem;
  }
  .pd2 {
    padding-left: 10px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #4528dc;
    left: 0 px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .header-card .card {
    width: 20rem;
    margin-top: 30px;
    max-width: 90%;
  }
}

/* explore area */
.explore-area .explore-menu .btn {
  background: inherit;
  color: var(--white-color);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.explore-area .explore-menu .btn::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 0;
  bottom: 5px;
  left: 50%;
  background-color: var(--primary-color);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.active {
  color: var(--primary-color);
}
.explore-area .explore-menu .btn.active::after {
  width: 60%;
  visibility: visible;
  opacity: 1;
}

.explore-area .card .card-body a h5 {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.explore-area .card .card-body a:hover h5 {
  color: var(--primary-color);
}

.explore-area .card .seller a h6 {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.explore-area .card .seller a:hover h6 {
  color: var(--primary-color);
}

/* Card */
.card-columns {
  margin: 0 15px;
  -webkit-column-gap: 30px;
  column-gap: 30px;
}

.card-columns .card {
  margin-bottom: 30px;
}

.card {
  padding: 1.5rem;
  border: none;
  border-radius: 8px;
  background-color: var(--card-bg-color);
  -webkit-box-shadow: 0 3px 20px 0px var(--primary-l-color);
  box-shadow: 0 3px 20px 0px var(--primary-l-color);
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
}

.card .card-img-top {
  border-radius: 6px;
}

.card .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 35px 0 0;
}

.card .card-body h3,
.card .card-body h4,
.card .card-body h5,
.card .card-body h6 {
  color: #000;

  margin-top: 0;
}

.card .card-body .card-bottom span {
  color: #000;
}

.card .card-footer {
  border-radius: 0;
  background: none;
  padding: 30px 0;
  margin: 0 30px;
  border-top: 1px solid var(--primary-l-color);
}

.card .card-footer a {
  margin: 0 10px;
}

.card .card-footer a:not(.btn) {
  color: var(--primary-p-color);
}

.card .card-footer .card-footer i {
  margin: 0 5px 2.5px;
}

.card:hover .card-footer {
  color: #000;
  border-color: rgba(255, 255, 255, 0.25);
}

.card.no-hover {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-ccash {
  padding: 16px 31px !important;
}
.card.no-hover:hover {
  -webkit-transform: inherit;
  transform: inherit;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.card:not(.no-hover):hover {
  -webkit-transform: translateY(-5px);
  box-shadow: 0 16px 32px #cfd8dc;
  transform: translateY(-5px);
}
.space-img {
  height: 200px;
  width: 100%;
}
button:disabled,
button[disabled] {
  border: none !important;
  opacity: 0.45;
}
.intro {
  margin-bottom: 60px;
}

.intro span {
  position: relative;
  display: inline-block;
  font-weight: 600;

  text-transform: uppercase;
}
.clip-text,
.text-effect,
.intro span,
.hero-section span,
.share-list .nav-link i {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-text,
.btn .text-effect,
.intro span,
.hero-section span,
.share-list .nav-link i {
  background-image: linear-gradient(
    150deg,
    var(--secondary-color) 0%,
    var(--primary-color) 78%
  );
}
.intro span::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 45px;
  background-image: linear-gradient(
    135deg,
    var(--primary-color) 10%,
    var(--secondary-color)
  );
  top: 50%;
  left: calc(100% + 20px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.intro h1:first-child,
.intro h2:first-child,
.intro h3:first-child {
  margin-top: 0;
}

.intro.text-center span::after {
  content: none;
}

/* Main intro image */
img.vert-move {
  -webkit-animation: mover 3s infinite alternate;
  animation: mover 3s infinite alternate;
}
img.vert-move {
  -webkit-animation: mover 3s infinite alternate;
  animation: mover 3s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* Link Style 3 */
.link3 a {
  color: #8e8e8e;
  text-decoration: none;
  border-bottom: 0.125em solid #530a98;
  box-shadow: inset 0 -0.125em 0 #530a98;
  transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1),
    color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.link3 a:hover {
  box-shadow: inset 0 -1.125em 0 #530a98;
  color: #fff;
}

.link3 a:focus {
  background: #fff;
  outline: none;
  background: #530a98;
  color: #fff;
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.2);
}
.listed-price {
  font-size: 14px;
}
/* breadcrumb */
.breadcrumb-area {
  background: rgba(0, 0, 0, 0) url("../images/inner_bg.jpg") no-repeat scroll
    top center/cover;
}
.overlay-dark {
  position: relative;
  z-index: 0;
}

.overlay-dark::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--primary-bg-color);
  opacity: 0.84;
  z-index: -1;
}
.breadcrumb-area.bg-overlay::after {
  opacity: 0.89;
}

.breadcrumb {
  margin: 0;
  background: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: inherit;
}

.breadcrumb-area {
  height: 450px;
  z-index: 1;
}

.breadcrumb-area .breadcrumb-item {
  font-size: 1rem;
  font-weight: 600;
  margin: 0.3rem 0;
}

.breadcrumb-area .breadcrumb-item a:not(.btn) {
  font-weight: 600;
  color: var(--white-color) !important;
}

.breadcrumb-area .breadcrumb-item a:hover {
  color: var(--white-color);
}

.breadcrumb-area .breadcrumb-item.active {
  color: var(--white-color) !important;
}

.breadcrumb-area .breadcrumb-item.active:hover {
  color: var(--primary-color);
}

.breadcrumb-area .breadcrumb-content h2 {
  font-weight: var(--h1-weight);
}

.breadcrumb-area .breadcrumb-content .breadcrumb {
  background-color: transparent;
  margin: 0;
}

.breadcrumb-area
  .breadcrumb-content
  .breadcrumb
  .breadcrumb-item
  + .breadcrumb-item::before {
  content: ">";
  color: var(--white-color);
}
/* ...Footer */

.footer-area .footer-items .footer-title {
  margin-top: 0;
  color: var(--white-color);
}

.footer-area .footer-items .navbar-brand {
  padding: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 0;
  color: var(--white-color);
}

.new-social-icons .fab {
  display: block;
  height: 100%;
  line-height: 45px;
  margin: 0 auto;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.new-social-icons > a {
  margin: 5px;
  width: 45px;
  height: 45px;
  font-size: 20px;
  background: #222;
  color: var(--white-color) !important;
  border-radius: 3px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.new-social-icons > a:hover .fab:first-child {
  margin-top: -45px;
}
.footer-area .footer-items .navbar-brand img {
  cursor: pointer;
}

.footer-area .footer-items .new-social-icons > a {
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
}

.footer-area .footer-items .new-social-icons > a:hover .fab:first-child {
  margin-top: -40px;
}

.footer-area .footer-items .subscribe-form {
  position: relative;
}
.footer-area .footer-items p {
  font-weight: 400;
}

.btn {
  background-image: linear-gradient(
    150deg,
    var(--secondary-color) 0%,
    var(--primary-color) 78%
  );
}
.footer-area .footer-items .subscribe-form .form-control {
  height: 50px;
  border: none;
  outline: none;
  padding-left: 20px;
  border-radius: 6px;
  background-color: #191919;
  color: var(--secondary-p-color);
}

.footer-area .footer-items .subscribe-form .form-control:focus {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer-area .footer-items .subscribe-form input {
  font-size: 14px;
  text-indent: 3%;
}

@media (max-width: 575px) {
  .footer-area .footer-items .subscribe-form input {
    text-indent: 0;
  }
}

.footer-area .footer-items .subscribe-form input::-webkit-input-placeholder {
  color: var(--primary-p-color);
}

.footer-area .footer-items .subscribe-form input:-ms-input-placeholder {
  color: var(--primary-p-color);
}

.footer-area .footer-items .subscribe-form input::-ms-input-placeholder {
  color: var(--primary-p-color);
}

.footer-area .footer-items .subscribe-form input::placeholder {
  color: var(--primary-p-color);
}

.footer-area .footer-items .subscribe-form button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 16px 25px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.footer-area .footer-items ul {
  padding-left: 0;
}

.footer-area .footer-items ul li {
  margin: 0.8rem 0;
  list-style: none;
}

.footer-area .footer-items ul li a {
  color: var(--secondary-p-color);
}

.footer-area .copyright-area {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-area .copyright-area a {
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 575px) {
  .footer-area .col-12:last-of-type .footer-items {
    margin-bottom: 0;
  }
}

/* NFT Card */

.card {
  width: 22rem;
  max-width: 100%;
  border: solid 4px #fff;
  border-radius: 2rem;
  padding: 0.75rem;
  text-decoration: none;
  color: inherit;
  background: #eceff1;
  transition: all 400ms ease;
}
.card:hover {
  background: #fff;
  box-shadow: 0 16px 32px #cfd8dc;
  transition: all 400ms ease;
}
.card__body {
  background: #fff;
  border-radius: 1.25rem;
  padding: 0.75rem;
}
.card__top {
  display: flex;
  font-weight: 500;
}
.card__owner,
.card__creator {
  width: 50%;
  display: flex;
}
.card__owner {
  position: relative;
}
.card__owner::after {
  content: "";
  position: absolute;
  top: 0;
  right: -1px;
  width: 2px;
  height: 100%;
  border-radius: 2px;
  background: #eceff1;
}
.card__creator {
  flex-direction: row-reverse;
  text-align: right;
}
.card__avatar {
  width: 3rem;
  height: 3rem;
  background: #eceff1;
  border-radius: 100%;
  flex-shrink: 0;
}
.card__user {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card__user__title {
  font-size: 0.75rem;
  color: #b0bec5;
}
.card__image {
  padding-top: 75%;
  position: relative;
  overflow: hidden;
  margin-top: 0.75rem;
  border-radius: 1rem;
  background: #eceff1;
  box-shadow: 0 16px 32px #eceff1;
}
.card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card__info {
  margin: 0.75rem;
  display: flex;
  justify-content: space-between;
}
.card__info p {
  margin: 0;
}
.card__footer {
  background: #fff;
  padding: 0.75rem;
  border-radius: 0.75rem 0.75rem 1.75rem 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__btn {
  width: 48%;
  padding: 1rem;
  text-align: center;
  font-weight: 500;
  transition: all 200ms ease;
}
.card__btn--primary {
  background: #263238;
  color: #eceff1;
  border-radius: 0.5rem 0.5rem 1.25rem 0.5rem;
}
.card__btn--primary:hover {
  background: #304ffe;
}
.card__btn--secondary {
  border-radius: 0.5rem 0.5rem 0.5rem 1.25rem;
}
.card__btn--secondary:hover {
  background: #eceff1;
  color: #304ffe;
}

/*----------------------------------------------
. Blog
----------------------------------------------*/
.single-widget {
  margin-bottom: 35px;
}

.single-widget h4 {
  font-weight: 700;
  border-bottom: 2px solid var(--card-bg-color);
}

.sidebar .single-widget:last-child {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .blog .col-12.col-sm-6 {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .blog .single-comments {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.blog .single-widget ul li {
  margin: 1.2rem 0;
}

.blog .single-widget .tags-widget a {
  border: 1px solid var(--primary-bg-color-3);
}

.blog .single-widget .post-widget h6 {
  font-size: var(--h5-size);
}

.blog .pagination > li > a {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  color: var(--primary-p-color);
  border: 1px solid var(--primary-bg-color-3);
}

.blog .pagination > li.active > a {
  color: var(--white-color);
  border: none;
}

.post-details .blog-thumb img {
  border-radius: 4px;
}

.post-details blockquote {
  margin: 1rem 2rem;
  padding: 2rem 1.5rem;
  border-left: 5px solid var(--primary-color);
  font-size: 1.2rem;
  color: var(--primary-color);
  background-color: rgba(1, 114, 128, 0.05);
}

@media (max-width: 575px) {
  .post-details blockquote {
    font-size: 100%;
  }
}

.post-details .sidebar .title {
  position: relative;
  font-weight: 700;
}

.post-details .sidebar .title::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 50px;
  background-color: var(--primary-color);
  left: 3px;
  top: calc(100% + 10px);
}

.post-holder {
  list-style: none;
  width: 100%;
  border-left: 5px solid var(--primary-color);
  background-color: var(--card-bg-color);
  padding: 25px;
  margin-top: 35px;
}
.author-area .card .card-body .input-group {
  position: relative;
  z-index: 1;
}

.author-area .card .card-body .input-group .form-control {
  border-radius: 100px;
  background-color: transparent;
  border: 2px solid;
  height: 40px;
  padding: 1.5rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.author-area .card .card-body .input-group .input-group-append {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 100px;
  overflow: hidden;
}

.author-area .card .card-body .input-group .input-group-append button {
  margin-right: 5px;
  padding: 12px 16px;
  background-color: transparent;
  color: var(--white-color);
  border: 0;
  outline: none;
}
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
.bg-grey input,
.bg-grey textarea {
  background-color: var(--white-color);
}

::-webkit-input-placeholder {
  color: var(--primary-p-color) !important;
}

:-ms-input-placeholder {
  color: var(--primary-p-color) !important;
}

::-ms-input-placeholder {
  color: var(--primary-p-color) !important;
}

::placeholder {
  color: var(--primary-p-color) !important;
}

form {
  width: 100%;
}

form label {
  width: 100%;
}

input,
textarea,
select {
  width: 100%;
  height: 50px;
  background: no-repeat;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0.7rem 1rem;
  border-color: var(--primary-l-color);
  border-width: 2px;
  border-radius: 2px;
}

input:focus,
textarea:focus,
select:focus {
  background: no-repeat;
  color: inherit;
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input {
  border-color: var(--primary-p-color);
  color: var(--primary-p-color);
}

input:focus {
  border-color: var(--primary-t-color);
  color: var(--primary-t-color);
}

input[type="radio"] {
  height: initial;
}

input[type="submit"] {
  border: 1px solid;
  padding: 2.5px 10px;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 200px;
  text-align: center;
  color: var(--primary-color);
}

input[type="submit"]:hover {
  background-color: var(--primary-color);
  color: var(--primary-b-color);
  border-color: var(--primary-color);
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

textarea {
  height: 100px;
  resize: none;
  border-color: var(--primary-p-color);
  color: var(--primary-t-color);
}

textarea:focus {
  border-color: var(--primary-t-color);
  color: var(--primary-t-color);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
  line-height: 1.2;
  border-color: var(--primary-l-color);
  color: var(--primary-t-color);
}

select option {
  color: #2f323a;
}

select:focus {
  border-color: var(--primary-t-color);
  color: var(--primary-t-color);
}

hr {
  margin: 35px 0;
  border-top: 1px dashed var(--primary-l-color);
}

.odd hr {
  margin: 35px 15px;
  border-top: 1px dashed var(--secondary-l-color);
}

table {
  width: 100%;
}

tr:not(:last-child) {
  border-bottom: 1px solid;
  border-color: var(--primary-l-color);
}

.odd tr:not(:last-child) {
  border-bottom: 1px solid;
  border-color: var(--secondary-l-color);
}

td {
  padding: 10px 0;
}
.color-black {
  color: #000;
}
