.cubbybox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;

  /* align-items: center; */
}

.cubbybox-item {
  background-color: #fef1d2;
  border-radius: 15px;
  padding: 10px;
  margin: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 225px;
  /* height: 400px; */
}

.container-container {
  background-color: #a200ff;
  /* border-radius: 20px; */
  color: #ffffff;
  /* border: 1px ridge #fd5da8; */
  align-content: center;

  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: space-around; */
  /* justify-content: space-between; */
  padding: 5px;
  align-items: center;
  /* width: 325px; */
  margin-bottom: auto;
}

.chubby-color {
  /* border-radius: 20px; */
  color: #4723d0;
}
.mintingInfo-container {
  /* background-color: #444444; */
  /* border-radius: 20px; */
  color: #4723d0;
  /* border: 1px ridge #fd5da8; */

  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  /* justify-content: space-between; */
  margin-bottom: 10px;
  padding: 5px;

  /* align-items: center; */
  /* width: 325px; */
}

.cryptocubbyinfo-container {
  background-color: #444444;
  border-radius: 20px;
  color: #4723d0;
  /* border: 1px ridge #fd5da8; */
  /* ridg: #00ff00; */
  /* padding: 16; */

  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: space-around; */
  justify-content: space-between;

  /* align-items: center; */
  width: 325px;
}

.cryptocubbyinfo-header {
  border-radius: 20px;
  padding: 12px;
  flex-direction: column;
  margin: 15px;
  /* display: flex; */
  /* flex-wrap: wrap; */
  color: #4723d0;
  /* justify-content: space-around; */
  /* align-items: center; */
  /* width: 100%; */
  /* height: 25px; */
}
